.mistakeInfo {
  display: flex;
  position: fixed;
  flex-direction: column;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 0.3rem;
  background-color: #fcc;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  font-size: 1.2rem;
}
